<template>
  <svg width="24" height="24" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30.5" cy="30.5" r="30.5" fill="url(#paint0_linear_1_1211)"/>
    <path
        d="M45.6072 33.0981C47.6072 31.9434 47.6072 29.0566 45.6072 27.9019L25.1965 16.1178C23.1965 14.9631 20.6965 16.4064 20.6965 18.7158V42.2841C20.6965 44.5935 23.1965 46.0369 25.1965 44.8822L45.6072 33.0981Z"
        fill="white"
    />
    <defs>
      <linearGradient
          id="paint0_linear_1_1211"
          x1="8.41379"
          y1="-4.34093e-06"
          x2="57.8601"
          y2="6.94013"
          gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE210C"/>
        <stop offset="1" stop-color="#FEBD29"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style></style>
